html, body {
  height: 100%;
}

.page-head, .page-content {
  @apply p-4;
}

.page-content {
  @apply pb-20;
}

.page-head {
  @apply flex text-white;
  min-height: 100px;
  background: url("/assets/images/page-head.png") mat.get-color-from-palette($veratrak-light-blue, 500) center;

  h1 {
    @apply mb-0;
  }

  .page-head-input {
    @apply flex rounded items-center bg-white text-black p-2;

    input {
      @apply outline-0;
      min-width: 220px;
    }

    .mat-icon {
      @apply mr-2;
    }
  }
}

#sidenav {
  width: 200px;
  background-color: mat.get-color-from-palette($veratrak-dark-blue, 500);

  .mat-list-item {
    font-size: 14px;
    color: #ccc;

    &.active {
      background-color: mat.get-color-from-palette($veratrak-light-blue, 500);
      color: white;
    }

    &:hover:not(.active) {
      background-color: mat.get-color-from-palette($veratrak-dark-blue, 700);
      color: white;
    }
  }
}

.mat-form-field {
  @apply w-full;
}

.mat-card-title {
  font-size: 20px;
}

.mat-table {
  .mat-row:hover {
    background-color: #fafafa;
  }
}

a {
  color: mat.get-color-from-palette($veratrak-light-blue, 500);
}

.tag {
  @apply p-1 rounded flex items-center text-xs text-white;
  background-color: mat.get-color-from-palette($veratrak-light-blue, 500);
}

.tag-in-progress {
  @apply p-1 rounded flex items-center text-xs text-white;
  background-color: #b8b894;
}

.tag-inactive {
  @apply p-1 rounded flex items-center text-xs text-white;
  background-color: mat.get-color-from-palette($veratrak-dark-blue, 300);
}

.tag-completed {
  @apply p-1 rounded flex items-center text-xs text-white;
  background-color: #4caf50;
}

.tag-cancelled {
  @apply p-1 rounded flex items-center text-xs text-white bg-red-500;
}

.mat-form-field:not(:last-child) {
  margin-bottom: 10px;
}

.snack-bar {
  background: mat.get-color-from-palette($veratrak-dark-blue, 500);
}

.mat-icon.mat-muted {
  color: mat.get-color-from-palette(mat.$gray-palette, 400);
}
.mat-icon.mat-green {
  color: mat.get-color-from-palette(mat.$green-palette, 500);
}
.mat-icon.mat-red{
  color: mat.get-color-from-palette(mat.$red-palette, 500);
}

.mat-form-field {
  &.mat-form-field-without-bottom-reserved-space {
    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }
  }
}
