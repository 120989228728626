// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@import './assets/styles/light-blue-palette';
@import './assets/styles/dark-blue-palette';

//@include mat.core();

$custom-typography: mat.define-typography-config(
  $font-family: "Inter, Helvetica Neue, sans-serif",
);

@include mat.core($custom-typography);

// Available color palettes: https://material.io/design/color/
$veratrak-primary: mat.define-palette($veratrak-light-blue);
$veratrak-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$veratrak-warn: mat.define-palette(mat.$red-palette);

$veratrak-theme: mat.define-light-theme((
  color: (
    primary: $veratrak-primary,
    accent: $veratrak-accent,
    warn: $veratrak-warn,
  )
));

@include mat.all-component-themes($veratrak-theme);
/* You can add global styles to this file, and also import other style files */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'assets/styles/globals';
